<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>
        <div class="col-md-10 col-lg-10 col-sm-12">
          <template>
            <div>
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50 col-md-2.5"
                @click="$router.go(-1)"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
              </b-button>
              <b-row class="match-height">
                <b-col md="6">

                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('details') }} {{ items.itemnumber_text }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('orderNumber') }} :
                            </template>

                            <p class="mt-50 font-weight-bolder">
                              {{ items.itemnumber_text }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('status') }} :
                            </template>
                            <p
                              v-if="items.status == 1"
                              class="mt-50 mb-0 badge badge-light-primary"
                            >
                              {{ $t('pendingReview') }}
                            </p>
                            <p
                              v-if="items.status == 2"
                              class="mt-50 mb-0 badge badge-light-success"
                            >
                              {{ $t('operationCompleted') }}
                            </p>
                            <p
                              v-if="items.status == 0"
                              class="mt-50 mb-0 badge badge-light-secondary"
                            >
                              {{ $t('cancelled') }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('taobaoID') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{ items.taobao_username }}
                              <i
                                v-clipboard:copy="items.taobao_username"
                                :class="iconClass[items.taobao_username] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                                @click="handleCopy(items.taobao_username)"
                              />
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('alipayID') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{ items.alipay_username }}
                              <i
                                v-clipboard:copy="items.alipay_username"
                                :class="iconClass[items.alipay_username] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                                @click="handleCopy(items.alipay_username)"
                              />
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('attachedFile') }} :
                            </template>
                            <img
                              v-if="items.file_cus"
                              :src="GetImg('Alipay_file',items.file_cus)"
                              alt="img"
                              width="100px"
                              class="rounded"
                              @click="showPicture(items.file_cus)"
                            >
                            <span v-else>-</span>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('remarks') }} :
                            </template>
                            <p class="mt-50 mb-0 font-weight-bolder ">
                              {{ items.remark_cus ? items.remark_cus:'-' }}
                            </p>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="6">

                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('key-56') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('transferAmount') }} :
                            </template>

                            <p class="mt-50 font-weight-bolder">
                              ¥ {{ Commas(items.amount) }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('exchangeRate') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              ¥1 = {{ items.rate }} ฿
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('useSystemBalance') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{ Commas(items.balance) }} ฿
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('transferEvidence') }} :
                            </template>

                            <div>
                              <img
                                v-if="items.fileadmin"
                                :src="GetImg('Alipay_file',items.fileadmin)"
                                alt="img"
                                width="100px"
                                class="rounded"
                                @click="showPicture(items.fileadmin)"
                              >
                              <span v-else>-</span>
                            </div>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-remark"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('remarks') }} :
                            </template>
                            <div>{{ items.remark_admin ? items.remark_admin: '-' }}</div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('relatedAmountList') }}
                      </h3>
                    </div>

                    <div class="pl-2 pr-2">
                      <b-table
                        responsive="sm"
                        :fields="fieldsRelevant"
                        :items="Transations"
                        show-empty
                      >
                        <template #empty="">
                          <b-table-empty-slot>
                            <div class="text-center my-2">
                              <img
                                src="@/assets/images/anan-img/svg/doc_empty.svg"
                                alt="empty"
                                height="60px"
                              >
                              <p class="text-muted">
                                No Data
                              </p>
                            </div>
                          </b-table-empty-slot>
                        </template>

                        <template #cell(amount)="data">
                          <span :style="getAmountStyle(data.item.type)">
                            {{ getAmountPrefix(data.item.type) }} {{ Commas(data.item.amount) }} ฿
                          </span>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </div>
      </div>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from '../component/SideBar.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    SideBar,
    VueImageLightboxCarousel,
    // ThisHeader,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: true,
      file: null,
      parcelCode: null,
      remark: null,
      Transaction: [],
      parcelList: [
        {
          value: '1',
          text: '1',
        },
        {
          value: '2',
          text: '2',
        },
        {
          value: '3',
          text: '3',
        },
      ],

      ID: this.$route.params.id,
      items: [],
      fileadmin: null,
      fileadminimg: null,
      Transations: [],
      iconClass: {},
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'created_at', label: this.$t('transactionDate'), thStyle: { width: '20%' }, formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '60%' } },
        {
          key: 'amount', label: this.$t('quantity'), thStyle: { width: '20%' }, formatter: value => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.getTransations()
  },
  methods: {
    async getTransations() {
      try {
        const params = {
          ref_id: this.ID,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })
        if (res.success) {
          this.Transations = res.data
        }

        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Alipayschina/getDataOne', { params })
        this.items = res
        this.fileadminimg = this.items.fileadmin
        this.remark = this.items.remark_admin
        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    fileImage(event) {
      if (event) {
        this.fileadmin = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.fileadmin[0]
        if (!filepath) {
          this.fileadminimg = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.fileadminimg = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    GetImg(pathfile, filename) {
      return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Alipay_file', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(amount)) {
          return { color: 'red' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'green' }
        }
      }
      return { color: 'orange' }
    },
    handleCopy(item) {
      if (item) {
        this.$set(this.iconClass, item, true)

        setTimeout(() => {
          this.$set(this.iconClass, item, false)
        }, 3500)
      }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(value)) {
          return '-'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '+'
        }
      }
      return ''
    },
    deleteImageorder() {
      this.$bvModal.msgBoxConfirm('ต้องการลบรูปภาพหรือไม่ ?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          this.fileadminimg = null
          this.fileadmin = null
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
